<template>
  <b-card class="p-1 full-screen container">
    <b-button
      class="mb-1"
      variant="primary"
      @click="$router.go(-1)"
    >
      Go Back
    </b-button>
    <iframe
      :src="file_source"
      width="100%"
      class="full-screen"
      frameborder="0"
    />
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      file_source: null,
    }
  },
  async created() {
    const { account_type } = this.$store.state.auth.ActiveUser
    if (account_type === this.$AccountTypes.EMPLOYER || account_type === this.$AccountTypes.AGENCY) {
      this.file_source = 'https://docs.google.com/document/d/1rK6a-rbpjqDskiK4sPaY6PELwFvJ0yQT5CXjhgUtLwA/edit?usp=sharing'
    } else {
      const fileURL = await this.$generateDownloadUrl('media/user-manual.pdf')
      this.file_source = `https://docs.google.com/viewer?url=${fileURL}&embedded=true`
    }
  },
  methods: {},
}
</script>

<style>
.full-screen {
  height: 100vh;
}
</style>
